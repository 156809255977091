import { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { NOT_AVAILABLE } from "./AppConstants";
import { setLoginState } from "../components/SMNavBar";
import {
  setLogoutFunction,
  setAuthorizationToken,
  fetchJsonWithAuth,
  setLoggedInUserId,
} from "../shared/fetch-json.util";
import { recordSAMUserActivity } from "./APIHelper";

export function useUserLogin() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [userSessionObj, setUserSessionObj] = useState(null);

  const logout = async () => {
    oktaAuth.stop();
    // Redirect to '/' after logout
    clearSessionInfo();
    oktaAuth.signOut({
      postLogoutRedirectUri: "https://jdsn.deere.com",
    });
  };

  function getAdjustedUserRoles(rolesObj, userType) {
    // Add SALARY as role when no role is assigned to salary employees
    return rolesObj && rolesObj.length > 0
      ? rolesObj
      : userType && userType.toUpperCase() === "SALARY"
      ? ["SALARY"]
      : [];
  }
  function setUserObjInSession(info, accessToken, rolesObj, msg) {
    let adjustedRoles = getAdjustedUserRoles(rolesObj, info.userType);
    let userObj = {
      userId: info.userID,
      userName: getFormattedUserNameInt(
        info.lastName,
        info.firstName,
        info.middleInitial
      ),
      token: accessToken,
      roles: adjustedRoles,
      hasNonStrategySDFAccess:
        adjustedRoles.includes("NON_STRATEGY_SDF") ||
        adjustedRoles.includes("SALARY"),
      message: msg,
    };
    // Set Global Login State for the header component
    setLoginState(userObj);
    setSessionInfo(userObj);
    setUserSessionObj(userObj);
  }
  useEffect(() => {
    const getUser = async () => {
      try {
        await oktaAuth.start();
        const accessToken = oktaAuth.getAccessToken();

        setAuthorizationToken(accessToken);
        setLogoutFunction(logout);

        const info = await oktaAuth.getUser();
        setUserInfo(info);
        setLogoutFunction(logout);
        setAuthorizationToken(accessToken);
        setLoggedInUserId(info.userID);

        // Find roles only for Non- Salaried
        info.userType && info.userType.toUpperCase() === "SALARY"
          ? setUserObjInSession(info, accessToken, null)
          : getUserRoles(info.userID, accessToken)
              .then((rolesObj) => {
                setUserObjInSession(info, accessToken, rolesObj);
                if (rolesObj.length > 0)
                  recordSAMUserActivity(info.userID.toUpperCase(), "SDF");
                }
                )
              .catch((error) => {
                setUserObjInSession(
                  info,
                  accessToken,
                  null,
                  "Role information inaccessible; please try again later"
                );
              });
        }
        catch (error) {
        console.log(error);
      }
    };
    authState?.isAuthenticated && getUser();
  }, [authState, oktaAuth]); // Update if authState changes

  async function getUserRoles(userId, token) {
    let postResp = await fetchJsonWithAuth(
      process.env.REACT_APP_USER_ROLE_API_URL + "/roles/" + userId
    );

    return postResp.json;
  }

  return [userInfo, logout, userSessionObj];
}

const setSessionInfo = (session) => {
  sessionStorage.setItem("session", JSON.stringify(session));
};
const clearSessionInfo = (session) => {
  sessionStorage.removeItem("session");
  sessionStorage.removeItem("searchCriteriaLabel");
  sessionStorage.removeItem("searchCriteria");
};

function getFormattedUserNameInt(lastName, firstName, middleInitial) {
  return (
    (lastName !== "" && lastName !== NOT_AVAILABLE ? lastName : "") +
    (firstName && firstName !== NOT_AVAILABLE ? ", " + firstName : "") +
    (middleInitial && middleInitial !== NOT_AVAILABLE
      ? " " + middleInitial
      : "")
  );
}

export function getFormattedUserName(userInfo) {
  let formatted = userInfo
    ? getFormattedUserNameInt(
      userInfo.lastName,
      userInfo.firstName,
      userInfo.middleInitial
    )
    : "";

  return formatted;
}
export function getFormattedUserNameWithId(userInfo, userId) {
  let formatted = userInfo ? getFormattedUserName(userInfo) : "";
  if (formatted !== "") formatted += " - ";

  formatted += userId.toUpperCase();
  return formatted;
}
export function getSessionInfo() {
  let obj = sessionStorage.getItem("session");

  return obj === undefined || obj === null ? null : JSON.parse(obj);
}
