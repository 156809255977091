export const oktaAuthConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: window.location.origin + "/implicit/callback",
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  pkce: true,
  scopes: process.env.REACT_APP_OKTA_SCOPES.split(' '),
  services: {
    autoRenew: true
  },
  tokenManager: {
    expireEarlySeconds: 60
  }
};
